type resultsDataType = {
  id: number
  fullName: string
  transactionNumber: string
  transactionValue: string
  transactionTime: string
}

const bynClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Савельев E.M.',
    transactionNumber: 'Дивиденды ***7321',
    transactionValue: '+1876.45 Br',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Лебедев O.B.',
    transactionNumber: 'Дивиденды ***4956',
    transactionValue: '+2589.78 Br',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Петрова Н.У.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+1423.12 Br',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Кулагин А.М.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+2314.67 Br',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Касенова З.Ж.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+2999.01 Br',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Федоров А.К.',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+1122.34 Br',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Алибекова С.С.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+2034.56 Br',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Белова Д.Д.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+2756.89 Br',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Абдулова Я.Б.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+1567.23 Br',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Давыдов Р.О.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+2211.45 Br',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Яковлев Ж.П.',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+1888.78 Br',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Орлова Ш.А.',
    transactionNumber: 'Дивиденды ***3621',
    transactionValue: '+2666.12 Br',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Алиева У.В.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+1313.34 Br',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Максимова А.Ч.',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+2777.56 Br',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Турлыби Т.Б.',
    transactionNumber: 'Дивиденды ***7512',
    transactionValue: '+1498.89 Br',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Горбаче Д.У.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+2045.01 Br',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Мирош П.П.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+2888.23 Br',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Жукова К.К.',
    transactionNumber: 'Дивиденды ***3102',
    transactionValue: '+1234.45 Br',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Ким Г.К.',
    transactionNumber: 'Дивиденды ***1786',
    transactionValue: '+2789.78 Br',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Белова З.Ж.',
    transactionNumber: 'Дивиденды ***6975',
    transactionValue: '+1023.90 Br',
    transactionTime: '08:42',
  },
]

export const data = bynClients
